import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';

import SEO from '../components/SEO';
import Layout from '../components/Layout';

const IndexPage = ({ intl }) => (
  <Layout fullMenu>
    <SEO title={intl.formatMessage({ id: '404_page_title' })} />
    <article id="main">
      <header>
        <h2>Not Found</h2>
        <p>Not a Valid URL</p>
      </header>
    </article>
  </Layout>
);

export default injectIntl(IndexPage);
